<template>
  <div class="col-12">
      <div class="card">
          <div class="card-body">
              <MarketOrders :orderType="'mdKeeperMarket'" />
          </div>
      </div>
  </div>
</template>

<script>
import MarketOrders from '@/components/miracle-deal/MarketOrders.vue';
export default {
  name: 'MarketKeepers',
  components: {
    MarketOrders,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  }
};
</script>
