<template>
  <transition name='fade'>
    <div class='modal show'>
      <div class='modal__backdrop'></div>
      <div class='modal__dialog'>
        <div class='modal__header'>
          <h1>{{ title }}</h1>
          <div class='modal__close' @click='closeModal()'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'>
              <path
                fill='currentColor'
                d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'
              ></path>
            </svg>
          </div>
        </div>
        <div class='modal__body'>
            <slot name='body' />
        </div>
        <div class='modal__footer'>
          <slot name='footer' />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'DealModal',
  props: {
    title: String,
  },
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
        this.$store.commit("global/SET_DEAL_MODAL", false);
        document.querySelector('body').classList.remove('overflow-hidden');
    }
  }
}
</script>

<style lang='scss' scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1031;

  &.show {
    display: block;
  }

  &__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__dialog {
    background-color: #ffffff;
    position: relative;
    width: 600px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;
    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }

  &__close {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  &__close:hover {
    opacity: .7;
  }

  &__header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__body {
    padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__footer {
    padding: 10px 20px 20px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>